// 引入 Vue
import Vue from 'vue'
// 引入 App
import App from './App.vue'
import VueResource from 'vue-resource'

// 關閉 Vue 的生產提示
Vue.config.productionTip = true

Vue.use(VueResource)

// 創建 vm
new Vue({
  el: '#app',
  render: h => h(App),
  beforeCreate () {
    Vue.prototype.$bus = this
  }
})
