<template lang="pug">
#app
  .container
    .result_box
      h1.time(v-if="result_status === 0") {{ mins.toString().padStart(2, "0") }}：{{ seconds.toString().padStart(2, "0") }}
      h1.analysis(v-if="result_status === 1") 
        img(src="@/assets/images/analyze-rotate.svg", alt="alt")
        | 上傳中
      h1.analysis(v-if="result_status === 2") 
        img(src="@/assets/images/analyze-rotate.svg", alt="alt")
        | 分析中
      h1.success(v-if="result_status === 3") 通過 PASS
      h1.fail(v-if="result_status === 4") 異常 FAIL

    .btn_group
      button.btn_type( type="button", :class="{ btn_start: !record_status }", @click="startRecord()") 開始錄音
      button.btn_type(type="button", :class="{ btn_end: record_status && result_status !== 1 }", @click="stopRecord()") 結束錄音

    .statistics_area(v-if="result_status === 3 || result_status === 4")
      .statistics_title 本次總計數量
      .statistics_list
        .statistics_item(v-if="result_status === 3") 通過：{{ result_num }}
        .statistics_item(v-if="result_status === 4") 異常：{{ result_num }}
</template>

<script>
import RecordLib from 'js-audio-recorder'
// import lamejs from 'lamejs'
export default {
  name: "App",
  components: {},
  data () {
    return {
      // 狀態
      result_status: 0,
      result_num: '',

      // 錄音狀態 停止:false 錄音中: true
      record_status: false,
      time: null,
      mins: 0,
      seconds: 0,

      // 處理錄音操作對象
      recorder: false,
    };
  },
  created () {
    // 初始化錄音元件
    this.recorder = new RecordLib({
      sampleBits: 16,
      sampleRate: 48000,
      numChannels: 1
    })
    // 處理方法作業
    this.recorder.onprogress = this.onProgress
  },
  methods: {
    // 計時器本人
    timer () {
      this.time = setInterval(() => {
        this.timeIncrease();
      }, 1000);
    },
    // 開始計時
    startRecord () {
      let vm = this
      this.mins = 0
      this.seconds = 0
      // 判斷目前狀態為未開始錄音
      if (!this.record_status) {
        // 啟動錄音作業
        this.recorder.start().then(() => {
          console.log('啟動錄音成功...')
          // 處理計時器
          vm.timer();
          vm.record_status = true;
          vm.result_status = 0;
        }, (_err) => {
          console.log('啟動錄音時發生錯誤:', _err)
        })
      }
    },
    // 時間增加
    timeIncrease () {
      if (this.seconds === 59) {
        this.mins += 1;
        this.seconds = 0;
      } else {
        this.seconds += 1;
      }
    },
    // 結束計時
    stopRecord () {
      // 判斷是否有計時器以及目前狀態為錄音中
      if (this.time && this.record_status) {
        this.recorder.stop()
        this.record_status = false;
        clearInterval(this.time);
        setTimeout(() => {
          this.getRecordMediaFile()
        }, 2000);
      }
    },
    getRecordMediaFile () {
      let vm = this

      let toltime = this.recorder.duration
      console.log('=> 錄音總時長:', toltime)

      let fileSize = this.recorder.fileSize
      console.log('=> 錄音檔案大小:', fileSize)

      // 獲得錄音檔案文件
      let wavContent = this.recorder.getWAVBlob()

      // 組合 POST 表單資訊
      var formData = new FormData();
      formData.append('file', new File([wavContent], new Date().getTime() + `.wav`, {
        type: 'audio/wav'
      }))

      // 請求上傳檔案到指定服務器
      this.result_status = 1
      this.$http.post("https://sona-uat.api.dwave.cc/v1/upload", formData, {
        headers: {
          'X-API-KEY': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZW52IjoiZHdhdmUtdWF0IiwidXVpZCI6IjZhNDcxMDNhLTlkN2UtNDRmMy1iYjIxLTY3NTNmYTk0NjNjZCIsInVzZXJfbmFtZSI6IlpZTFVYIiwiZXhwaXJlZF9hdCI6IjIwMjQtMDEtMDEgMDA6MDA6MDAiLCJ2ZXJzaW9uIjoidjEiLCJwZXJtaXNzaW9ucyI6WyJ1cGxvYWQ6KiIsImZldGNoOnNhZCJdfQ.rw2Qxr0tk-ConYDQluyTHWXzv9ChOx6XaYifVZrIzumHcB2tj6zw6t8gV11m0pRM2TRdhlRIhK50UurMETsHcQ'
        }
      }).then(function (_response) {
        console.log('==> 錄音檔案上傳 [成功]:', _response.body)
        vm.result_status = 1
        vm.processGoScanMedia(_response.body.upload_url)
      }, function (_error) {
        console.log('==> 錄音檔案上傳 [失敗]:', _error.body)
        alert("錄音檔案上傳發生問題！")
      })
    },
    // 通知後端服務器進行掃描作業
    processGoScanMedia (_filePath) {
      let vm = this
      this.result_status = 2
      this.$http.post("https://sona-uat.api.dwave.cc/v1/fetch/sad", {
        file_list: [
          {
            label: "scanning",
            path: _filePath
          }
        ],
        params: {
          file: "scanning"
        }
      }, {
        headers: {
          'X-API-KEY': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZW52IjoiZHdhdmUtdWF0IiwidXVpZCI6IjZhNDcxMDNhLTlkN2UtNDRmMy1iYjIxLTY3NTNmYTk0NjNjZCIsInVzZXJfbmFtZSI6IlpZTFVYIiwiZXhwaXJlZF9hdCI6IjIwMjQtMDYtMDEgMDA6MDA6MDAiLCJ2ZXJzaW9uIjoidjEiLCJwZXJtaXNzaW9ucyI6WyJ1cGxvYWQ6KiIsImZldGNoOnNhZCJdfQ.ukN_BNxbB1pxUnXG8SGn2Q0579jfWsvVRRX_a8VKhk-NHe7uuoGeevBSLf1ZdBMxXlR3gOvHQZTGe5e3p1jmCg'
        }
      }).then(function (_response) {

        let anser = _response.body.result.data.prediction
        if (anser === 'normal') {
          vm.result_status = 3
          vm.result_num = anser
          console.log('==> 錄音檔案 Scaning [成功]:', anser)
        }

        else {
          vm.result_status = 4
          vm.result_num = anser
          console.log('==> 錄音檔案 Scaning [失敗]:', anser)
        }

      }, function (_error) {
        vm.result_status = 4
        console.log('==> 錄音檔案 Scaning [失敗]:', _error.body)
        vm.result_num = (_error.body.code) ? _error.body.code : ""
      })
    },
    // 處理方法
    onProgress (_params) {
      console.log('錄音資訊:', _params)
    }
  },
};
</script>
<style lang="sass" scoped>
.container
  max-width: 960px
  margin: auto
  .result_box
    height: 210px
    h1
      font-size: 135px
      font-weight: 900
      text-align: center
      margin: auto
      background-color: #D2E4E1
      margin-top: 30px
      padding: 10px
      border: 1px solid #aaa
      border-radius: 4px
      box-shadow: 0 0 2px 0px #2101CA33
    .time,.analysis
      background-color: #DDDFF7
      color: #000
    .success
      background-color: #00AE07
      color: #FFF
    .fail
      background-color: #DF3A3A
      color: #FFF
    .analysis
      display: flex
      justify-content: center
      align-items: center
      img
        width: 100px
        vertical-align: middle
        margin-right: 50px

  .btn_group
    margin-top: 100px
    display: flex
    justify-content: space-between
    .btn_type
      width: 36%
      font-size: 50px
      font-weight: 500
      padding: 50px 0px
      border-radius: 10px
      box-shadow: 2px 3px 10px #0003
      cursor: pointer
      border: 4px solid #000
      color: #FFF
      background-color: #8F8F8F
    .btn_start
      color: #FFF
      background: #18A0FB
      border: 4px solid #2C88D9
      // &.active
      //   font-weight: bold
      //   color: #f00
      //   background-color: #ffc82d
      //   border: 4px solid #f00
      //   text-shadow: 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff

    .btn_end
      color: #FFF
      background: #00AE07
      border: 4px solid #F1B78C
      // &.active
      //   color: #f00
      //   font-weight: bold
      //   background-color: #ffc82d
      //   border: 4px solid #f00
      //   text-shadow: 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff

  .statistics_area
    margin-top: 120px
    .statistics_title
      font-size: 40px
      font-weight: 500
      text-align: center
    .statistics_list
      margin-top: 30px
      .statistics_item
        font-size: 40px
        font-weight: 500
        text-align: center
</style>